var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { FILTERS_EQUAL, FILTERS_IN, EDIT_PRIOR_WEEK_PERMISSION, EDIT_PERMISSION, PayloadHandlerMixin, ErrorHandlerMixin, UPDATE_SUCCESS, PROMOTIONAL_PLANNING_PROMO_ENTRY_SALES_PLAN } from "@kraftheinz/common";
import { P as PromoUtils } from "./promotion.planning.js";
import { n as normalizeComponent, l as COLOR_OBJECT_ENTRY_PRICE } from "./index.js";
import "numeral";
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-row", { staticClass: "mr-0 w-100", attrs: { "type": "flex", "gutter": [8, 8] } }, [_c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "form-item": _vm.isValidation, "allow-clear": "", "rules": "required", "label": "Region", "placeholder": "Select Region", "reference": "promotion-planning.common.regions", "source": "code", "source-label": "description", "source-description": "description", "api-not-get-paging": true, "clear-data-on-destroy": false, "clear-value-on-options-change": false, "value": _vm.filterSelect.Region }, on: { "change": function($event) {
      return _vm.onFilter("Region", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "form-item": _vm.isValidation, "allow-clear": "", "rules": "required|max_selected:5", "label": "Key Account", "mode": "multiple", "placeholder": "Select Key Account", "reference": "promotion-planning.common.customers", "source": "id", "source-description": "keyAccount", "is-clear-when-params-change": true, "fetch-options-on-filter": true, "api-not-get-paging": true, "advanced-filters": _vm.queriesByRegion, "clear-data-on-destroy": false, "clear-value-on-options-change": false, "max-tag-count": 2, "source-label": function(opt) {
      return _vm.generateLabel(opt, ["keyAccountCode", "keyAccount"]);
    }, "value": _vm.filterSelect.CustomerId }, on: { "change": function($event) {
      return _vm.onFilter("CustomerId", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("span", [_vm._v("Effective Weeks ")]), _c("div", { staticClass: "d-flex cus-mt" }, [_c("week-picker", { staticClass: "flex-1 mr-2 d-block-span", attrs: { "form-item": _vm.isValidation, "label": "From week", "show-label": false, "placeholder": "From week", "show-start-of-week": "", "rules": "required", "value": _vm.effectiveWeek.fromDate, "format-date": "DD-MM-YYYY" }, on: { "change": function($event) {
      return _vm.onChangeEffectiveDate("fromDate", $event);
    } } }), _c("week-picker", { staticClass: "flex-1 ml-2 d-block-span", attrs: { "form-item": _vm.isValidation, "label": "To week", "show-label": false, "placeholder": "To week", "show-start-of-week": "", "rules": "required", "format-date": "DD-MM-YYYY", "value": _vm.effectiveWeek.toDate, "disabled": !_vm.effectiveWeek.fromDate, "disable-date": _vm.disabledDate }, on: { "change": function($event) {
      return _vm.onChangeEffectiveDate("toDate", $event);
    } } })], 1)]), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "form-item": _vm.isValidation, "allow-clear": "", "rules": "required", "label": "Cluster", "placeholder": "Select Cluster", "reference": "promotion-planning.common.clusters", "source": "code", "source-description": "description", "custom-query": "all=false", "only-custom-query": true, "api-not-get-paging": true, "clear-data-on-destroy": false, "clear-value-on-options-change": false, "source-label": function(opt) {
      return _vm.generateLabel(opt, ["code", "description"]);
    }, "value": _vm.filterSelect.Cluster }, on: { "change": function($event) {
      return _vm.onFilter("Cluster", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "form-item": _vm.isValidation, "allow-clear": "", "rules": "required", "label": "Category", "placeholder": "Select Category", "reference": "promotion-planning.common.categories", "source": "code", "source-description": "description", "custom-query": "all=false", "is-clear-when-params-change": true, "only-custom-query": true, "max-tag-count": 2, "fetch-options-on-filter": true, "api-not-get-paging": true, "custom-url": _vm.apiUrl + "/promotion-planning/common/categories/" + _vm.filterSelect.Cluster, "clear-data-on-destroy": false, "clear-value-on-options-change": false, "source-label": function(opt) {
      return _vm.generateLabel(opt, ["code", "description"]);
    }, "value": _vm.filterSelect.Category }, on: { "change": function($event) {
      return _vm.onFilter("Category", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "form-item": _vm.isValidation, "allow-clear": "", "rules": "required|max_selected:3", "placeholder": "Select Sub Category", "reference": "promotion-planning.common.sub-categories", "source": "code", "source-description": "description", "label": "Sub Category", "mode": "multiple", "custom-query": "all=false", "is-clear-when-params-change": true, "only-custom-query": true, "max-tag-count": 2, "fetch-options-on-filter": true, "api-not-get-paging": true, "custom-url": _vm.apiUrl + "/promotion-planning/common/sub-categories/" + _vm.filterSelect.Category, "source-label": function(opt) {
      return _vm.generateLabel(opt, ["code", "description"]);
    }, "clear-data-on-destroy": false, "clear-value-on-options-change": false, "value": _vm.filterSelect.SubCategory }, on: { "change": function($event) {
      return _vm.onFilter("SubCategory", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "form-item": _vm.isValidation, "allow-clear": "", "rules": "max_selected:10", "label": "PPG Code", "mode": "multiple", "placeholder": "Select PPG", "reference": "promotion-planning.common.products", "source": "code", "source-description": "description", "custom-query": "all=false&distinct=true", "source-label": function(opt) {
      return _vm.generateLabel(opt, ["code", "description"]);
    }, "advanced-filters": _vm.ppgQueries, "fetch-options-on-filter": true, "is-clear-when-params-change": true, "api-not-get-paging": true, "clear-data-on-destroy": false, "clear-value-on-options-change": false, "max-tag-count": 2, "value": _vm.filterSelect.PPGCode }, on: { "change": function($event) {
      return _vm.onFilter("PPGCode", $event);
    } } })], 1), _c("a-col", { staticClass: "d-flex align-items-center", attrs: { "span": 6 } }, [_c("a-button", { staticClass: "mr-2", on: { "click": _vm.onClearFiltersClick, "mouseleave": _vm.setIsValidation } }, [_vm._v(" Clear Filters ")]), _c("a-button", { attrs: { "type": "primary" }, on: { "click": function($event) {
      return handleSubmit(_vm.onApplyFilter);
    } } }, [_vm._v(" Apply Filters ")])], 1)], 1)];
  } }]) });
};
var staticRenderFns$3 = [];
const initialFilter = {
  Cluster: void 0,
  CustomerId: [],
  Region: void 0,
  Category: void 0,
  PPGCode: [],
  SubCategory: []
};
const eqOperator = ["CustomerId", "SubCategory", "PPGCode"];
const stateNational = { AU: "17", NZ: "08" };
const __vue2_script$3 = {
  name: "FilterRetail",
  mixins: [PromoUtils],
  inject: ["resourceProps", "crud", "apiUrl"],
  data() {
    const [, , , , , productProps, ,] = this.resourceProps;
    return {
      productProps,
      filterSelect: __spreadValues({}, initialFilter),
      effectiveWeek: {
        fromDate: null,
        toDate: null
      },
      isValidation: true
    };
  },
  computed: {
    queriesByRegion() {
      if (!this.filterSelect.Region)
        return null;
      return [
        [
          "Region",
          { operator: FILTERS_EQUAL, value: this.filterSelect.Region }
        ]
      ];
    },
    ppgQueries() {
      if (!this.filterSelect.Region || !this.filterSelect.Cluster || !this.filterSelect.Category || !this.filterSelect.CustomerId || !this.filterSelect.SubCategory)
        return null;
      return [
        [
          "Region",
          { operator: FILTERS_EQUAL, value: this.filterSelect.Region }
        ],
        [
          "CustomerId",
          { operator: FILTERS_IN, value: this.filterSelect.CustomerId }
        ],
        [
          "State",
          {
            operator: FILTERS_IN,
            value: stateNational[this.filterSelect.Region]
          }
        ],
        [
          "Cluster",
          { operator: FILTERS_EQUAL, value: this.filterSelect.Cluster }
        ],
        [
          "Category",
          { operator: FILTERS_EQUAL, value: this.filterSelect.Category }
        ],
        [
          "SubCategory",
          { operator: FILTERS_IN, value: this.filterSelect.SubCategory }
        ]
      ];
    }
  },
  methods: {
    onApplyFilter() {
      if (!this.filterSelect.PPGCode) {
        this.$notification.warning({
          message: "Only 20 PPGs are being displayed."
        });
      }
      this.$emit("onApplyFilters", this.filterSelect);
    },
    async onFilter(key, value) {
      if (!value || value === 0 || value.length == 0) {
        this.filterSelect[key] = void 0;
        this.crud.deleteFilter(key);
      } else {
        this.filterSelect[key] = value;
        this.crud.setFilter(key, {
          operator: eqOperator.includes(key) ? "In" : "Eq",
          value
        });
      }
    },
    setIsValidation() {
      this.isValidation = true;
    },
    disabledDate(current) {
      if (!current)
        return false;
      const fromDate = this.effectiveWeek.fromDate;
      const chosenDate = this.$moment(fromDate).startOf("week");
      const maxSelectableWeek = chosenDate.clone().add(53, "weeks");
      const minSelectableWeek = chosenDate.add(1, "days").clone().subtract(0, "weeks");
      return current < minSelectableWeek || current > maxSelectableWeek;
    },
    onChangeEffectiveDate(key, date) {
      this.effectiveWeek[key] = date;
      this.crud.setQueryString(key, date);
    },
    async onClearFiltersClick() {
      this.isValidation = false;
      this.crud.clearFilters(), this.crud.deleteQueryStrings(), this.crud.deleteFilter("IsInactive");
      this.filterSelect = __spreadValues({}, initialFilter);
      this.effectiveWeek = {
        fromDate: null,
        toDate: null
      };
      this.productProps.crud.clearList();
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, null, null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var RetailFilter = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("div", { staticClass: "d-flex justify-content-end" }, [_vm._v(" Show Units "), _c("a-switch", { staticClass: "ml-2 mr-4 mb-2", attrs: { "disabled": !_vm.dataTable }, on: { "change": _vm.onShowUnit }, model: { value: _vm.isShowUnits, callback: function($$v) {
    _vm.isShowUnits = $$v;
  }, expression: "isShowUnits" } })], 1), _vm.dataTable ? _c("div", { staticClass: "ant-table ant-table-bordered ant-table-default ant-table-scroll-position-left ant-table-default" }, [_c("div", { staticClass: "ant-table-content" }, [_c("div", { staticStyle: { "overflow": "scroll" } }, [_c("div", { staticClass: "ant-table-body", staticStyle: { "max-height": "500px" } }, [_c("table", { staticClass: "table-hover scrolling-table" }, [_c("colgroup", [_c("col", { key: "week", staticStyle: { "width": "150px" } }), _vm._l(_vm.columns, function(col) {
    return _c("col", { key: col.key, style: { width: col.width ? col.width + "px" : "auto" } });
  })], 2), _c("thead", { staticClass: "ant-table-thead" }, [_c("tr", [_c("th", { key: "week", staticClass: "fixed-table fixed-table__left fixed-table__top", staticStyle: { "z-index": "200" } }, [_vm._v(" Week ")]), _vm._l(_vm.columns, function(col) {
    return _c("th", { key: col.key, staticClass: "ant-table-row-cell-break-word fixed-table fixed-table__top" }, [_vm._v(" " + _vm._s(col.title) + " ")]);
  })], 2)]), _vm._l(_vm.dataTable, function(subCategory, subCategoryCode) {
    return _c("tbody", { key: subCategoryCode, staticClass: "ant-table-tbody" }, [_c("tr", { staticClass: "ant-table-row ant-table-row-level-0" }, [_c("td", { staticClass: "fixed-table fixed-table__left border-left-none" }, [subCategory.ppg && subCategory.ppg ? _c("a-icon", { staticClass: "mr-2", attrs: { "type": !_vm.expandedRows.includes(subCategoryCode) ? "plus-square" : "minus-square" }, on: { "click": function($event) {
      return _vm.toggleRow(subCategoryCode);
    } } }) : _vm._e(), _vm._v(_vm._s(subCategory.subCategory) + " ")], 1), _c("td", { attrs: { "colspan": _vm.columns.length } })]), _vm.expandedRows.includes(subCategoryCode) ? _c("tr", [_c("td", { staticClass: "pd-0 border-left-none", attrs: { "colspan": Number(_vm.columns.length) + 1 } }, [_c("table", { staticClass: "table-hover" }, [_c("colgroup", [_c("col", { key: "week", staticStyle: { "width": "150px" } }), _vm._l(_vm.columns, function(col) {
      return _c("col", { key: col.key, style: {
        width: col.width ? col.width + "px" : "auto"
      } });
    })], 2), _vm._l(subCategory.ppg, function(ppg, ppgCode) {
      return _c("tbody", { key: ppgCode, staticClass: "ant-table-tbody" }, [_c("tr", { staticClass: "ant-table-row ant-table-row-level-0" }, [_c("td", { staticClass: "fixed-table fixed-table__left border-left-none" }, [_c("p", { staticClass: "ml-2" }, [ppg.keyAcc ? _c("a-icon", { staticClass: "mr-2", attrs: { "type": !_vm.expandedRows.includes(ppgCode) ? "plus-square" : "minus-square" }, on: { "click": function($event) {
        return _vm.toggleRow(ppgCode);
      } } }) : _vm._e(), _vm._v(_vm._s(ppgCode + " - " + ppg.ppg) + " ")], 1)]), _c("td", { attrs: { "colspan": _vm.columns.length } })]), _vm.expandedRows.includes(ppgCode) ? _c("tr", [_c("td", { staticClass: "pd-0 border-left-none", attrs: { "colspan": Number(_vm.columns.length) + 1 } }, _vm._l(ppg.keyAcc, function(keyAcc, keyAccountCode) {
        return _c("table", { key: keyAccountCode, staticClass: "ant-table-row ant-table-row-level-0" }, [_c("colgroup", [_c("col", { key: "week", staticStyle: { "width": "150px" } }), _vm._l(_vm.columns, function(col) {
          return _c("col", { key: col.key, style: {
            width: col.width ? col.width + "px" : "auto"
          } });
        })], 2), _c("tbody", { staticClass: "ant-table-tbody" }, [_c("tr", [_c("td", { staticClass: "fixed-table fixed-table__left border-left-none" }, [_c("p", { staticClass: "ml-4" }, [_vm._v(" " + _vm._s(keyAccountCode + " - " + keyAcc.keyAccount) + " ")])]), _vm._l(keyAcc.weeklyPrices, function(weeklyPrice, idx) {
          return _c("td", { key: idx, class: {
            "custom-disabled": _vm.checkPermission(weeklyPrice)
          } }, [_c("a-dropdown", { attrs: { "trigger": ["click"], "disabled": _vm.checkPermission(weeklyPrice) } }, [_c("a", { staticClass: "ant-dropdown-link", on: { "click": function(e) {
            return e.preventDefault(_vm.onWeeklyPriceInputClick(weeklyPrice));
          } } }, [!_vm.isShowUnits ? _c("div", { staticClass: "number-input" }, [_c("div", { staticClass: "ant-input-number", style: _vm.getcolorList(keyAcc.guardrails, weeklyPrice, _vm.isShowUnits ? weeklyPrice.unit : false).style }, [_c("span", { staticClass: "ant-input-number-input d-flex align-items-center" }, [_vm._v(" " + _vm._s(_vm.formatCurrency(weeklyPrice[_vm.keyWeekly])) + " ")])])]) : _vm.isShowUnits && weeklyPrice.seqNumber > 1 ? _c("a-form-item", { staticClass: "custom-error-form", attrs: { "has-feedback": "", "validate-status": _vm.isUnitBlank(weeklyPrice) ? "error" : "" } }, [_c("div", { on: { "mouseover": function($event) {
            _vm.iDSelected = weeklyPrice.id;
          }, "mouseleave": function($event) {
            _vm.iDSelected = null;
          } } }, [_c("a-tooltip", { attrs: { "overlay-class-name": "has-error", "visible": _vm.isUnitBlank(weeklyPrice) && _vm.iDSelected == weeklyPrice.id }, scopedSlots: _vm._u([!weeklyPrice.unit ? { key: "title", fn: function() {
            return [_vm._v(" Units cannot be blank ")];
          }, proxy: true } : null], null, true) }, [_c("number-input", { class: {
            "cursor-pointer": !_vm.isShowUnits,
            "is-bold": _vm.getcolorList(keyAcc.guardrails, weeklyPrice, _vm.isShowUnits ? weeklyPrice.unit : false).class
          }, attrs: { "disabled": _vm.checkPermission(weeklyPrice), "type": _vm.isShowUnits ? "number" : "currency", "custom-style": _vm.getcolorList(keyAcc.guardrails, weeklyPrice, _vm.isShowUnits ? weeklyPrice.unit : false).style, "is-hide-arrow": true, "is-blank": true, "value": weeklyPrice[_vm.keyWeekly] }, on: { "change": function($event) {
            return _vm.onInputPrice(weeklyPrice, _vm.keyWeekly, $event);
          } } })], 1)], 1)]) : _c("text-input", { attrs: { "disabled": "" } })], 1), _vm.getGuardrailsList(keyAcc.guardrails, weeklyPrice).length != 0 && !_vm.isShowUnits && !_vm.isShowUnits && (!weeklyPrice.promotionEntryId == 0 || !_vm.checkStartDate(weeklyPrice.startDay)) ? _c("a-menu", { attrs: { "slot": "overlay" }, slot: "overlay" }, [_vm._l(_vm.getGuardrailsList(keyAcc.guardrails, weeklyPrice), function(guardrail, guardrailIdx) {
            return [_c("a-menu-item", { key: guardrailIdx, style: {
              cursor: !_vm.canRenderPromo(guardrail) ? "not-allowed" : "pointer"
            } }, [_c("a", { staticClass: "text-right", style: "color: " + _vm.getGuardrailsName(guardrail.name).code + "; background-color: " + _vm.getGuardrailsName(guardrail.name).opacityColor, attrs: { "disabled": !_vm.canRenderPromo(guardrail), "href": "javascript:;" }, on: { "click": function($event) {
              _vm.onChangeRetail(weeklyPrice, guardrail, _vm.getGuardrailsList(keyAcc.guardrails, weeklyPrice));
            } } }, [_vm._v(" " + _vm._s(_vm.formatCurrency(guardrail[_vm.keyWeekly])) + " "), guardrail.seqNumber != 1 ? _c("span", [_vm._v(" (" + _vm._s(_vm.promoCounts[guardrail.promotionEntryId][guardrail.seqNumber].remainFrequency) + ") ")]) : _vm._e()])])];
          })], 2) : _vm._e()], 1)], 1);
        })], 2)])]);
      }), 0)]) : _vm._e()]);
    })], 2)])]) : _vm._e()]);
  })], 2)])])])]) : _vm.isLoading ? _c("div", [_c("div", { staticClass: "d-flex flex-column align-items-center" }, [_c("a-icon", { attrs: { "type": "loading" } })], 1)]) : _c("div", [_c("div", { staticClass: "d-flex flex-column align-items-center" }, [_c("a-icon", { style: { fontSize: "44px" }, attrs: { "type": "inbox" } }), _c("p", { staticClass: "ant-empty-description" }, [_vm._v("No Data")])], 1)])]);
};
var staticRenderFns$2 = [];
const __vue2_script$2 = {
  name: "TableRetail",
  inject: ["can"],
  props: {
    dataSource: {
      type: Array,
      default: null
    },
    columns: {
      type: Array,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      EDIT_PRIOR_WEEK_PERMISSION,
      EDIT_PERMISSION,
      objectColor: COLOR_OBJECT_ENTRY_PRICE,
      expandedRows: [],
      dataTable: null,
      promoCounts: {},
      clickedPromo: {
        promotionEntryId: null,
        seqNumber: null,
        unit: null,
        price: null
      },
      iDSelected: null,
      isShowUnits: false,
      count: 0
    };
  },
  computed: {
    keyWeekly() {
      return this.isShowUnits ? "unit" : "price";
    }
  },
  watch: {
    dataSource: {
      handler: function(val) {
        this.setDataTable(val);
      }
    }
  },
  methods: {
    async setDataTable(arr) {
      this.clickedPromo = {};
      await Promise.all([
        this.getObjectDataTableAndPromoCountFromArr(arr),
        this.getAllCodeToExpand(arr)
      ]).finally(() => {
        this.weeklyPrices = [];
      });
    },
    getObjectDataTableAndPromoCountFromArr(arr) {
      if (arr.length == 0)
        return this.dataTable = null;
      const result = {};
      const resultDataTable = {};
      arr.forEach((category) => {
        const subCategoryCode = category.subCategoryCode;
        const ppgObject = {};
        category.ppg.forEach((ppg) => {
          const ppgCode = ppg.ppgCode;
          const keyAccObject = {};
          ppg.keyAcc.forEach((keyAcc) => {
            const keyAccountCode = keyAcc.keyAccountCode;
            const guardrails = keyAcc.guardrails;
            const weeklyPrices = keyAcc.weeklyPrices;
            keyAccObject[keyAccountCode] = {
              keyAccount: keyAcc.keyAccount,
              guardrails,
              weeklyPrices
            };
            keyAcc.guardrails.forEach((guardrail) => {
              const promotionEntryId = guardrail.promotionEntryId;
              const seqNumber = guardrail.seqNumber;
              if (!result[promotionEntryId]) {
                result[promotionEntryId] = {};
              }
              result[promotionEntryId][seqNumber] = {
                frequency: guardrail.frequency,
                remainFrequency: guardrail.remainFrequency,
                checkFrequency: guardrail.checkFrequency,
                name: guardrail.name,
                ppg: ppg.ppgCode,
                keyAcc: keyAcc.keyAccount,
                seqNumber: guardrail.seqNumber
              };
            });
          });
          ppgObject[ppgCode] = {
            ppg: ppg.ppg,
            keyAcc: keyAccObject
          };
        });
        resultDataTable[subCategoryCode] = {
          subCategory: category.subCategory,
          ppg: ppgObject
        };
      });
      this.promoCounts = __spreadValues({}, result);
      this.dataTable = __spreadValues({}, resultDataTable);
    },
    getAllCodeToExpand(arr) {
      if (arr.length == 0)
        return;
      this.expandedRows = [];
      arr.forEach((item) => {
        const keyAccCode = item.subCategoryCode;
        item.ppg.forEach((pggItem) => {
          const pggCode = pggItem.ppgCode;
          pggItem.keyAcc.forEach((keyItem) => {
            const subCode = keyItem.keyAccountCode;
            this.expandedRows.push(...[keyAccCode, pggCode, subCode]);
          });
        });
      });
    },
    checkStartDate(dateToCheck) {
      var originalDate = new Date(dateToCheck);
      originalDate.setDate(originalDate.getDate() + 7);
      return new Date() >= new Date(originalDate);
    },
    checkPermission(weeklyPrice) {
      return !this.can(EDIT_PERMISSION) || !this.can(EDIT_PRIOR_WEEK_PERMISSION) && this.checkStartDate(weeklyPrice.startDay) || weeklyPrice.promotionEntryId == 0;
    },
    canRenderPromo(guardrail) {
      const { promotionEntryId, seqNumber } = guardrail;
      if (!this.promoCounts[promotionEntryId] || !this.promoCounts[promotionEntryId][seqNumber])
        return false;
      return this.promoCounts[promotionEntryId][seqNumber].remainFrequency != 0 || seqNumber == 1;
    },
    isInputUnit(guardrails, unit, promotionEntryId, seqNumber) {
      const guardrailsSelected = guardrails.find((val) => val.unit == unit && val.promotionEntryId == promotionEntryId && val.seqNumber == seqNumber);
      return guardrailsSelected ? guardrailsSelected.name : "OverWrite";
    },
    getcolorList(colorList, weeklyPrice, unit) {
      var _a;
      const guardrailsName = (_a = colorList.find((val) => val.seqNumber == weeklyPrice.seqNumber)) == null ? void 0 : _a.name;
      if (unit) {
        const inputUnit = this.isInputUnit(colorList, unit, weeklyPrice.promotionEntryId, weeklyPrice.seqNumber);
        const getGuardrailsNameWithInputUnit = this.getGuardrailsName(inputUnit, guardrailsName);
        return {
          style: `color: ${getGuardrailsNameWithInputUnit.code}; background-color: ${getGuardrailsNameWithInputUnit.opacityColor}`,
          class: getGuardrailsNameWithInputUnit.bold
        };
      }
      const getGuardrailsName = this.getGuardrailsName(guardrailsName);
      return this.checkPermission(weeklyPrice) ? {
        style: `color: ${getGuardrailsName.code}; background-color: #eaedf0; border: none`
      } : {
        style: `color: ${getGuardrailsName.code}; background-color: ${getGuardrailsName.opacityColor}`
      };
    },
    getGuardrailsName(key, keyIfUnitInput) {
      if (!key)
        return { code: "#000", opacityColor: "" };
      if (keyIfUnitInput) {
        return {
          code: this.objectColor[key.replace(/\s+/g, "")].code,
          opacityColor: this.objectColor[keyIfUnitInput.replace(/\s+/g, "")].opacityColor,
          bold: this.objectColor[key.replace(/\s+/g, "")].bold
        };
      }
      return this.objectColor[key.replace(/\s+/g, "")];
    },
    getGuardrailsList(arr, weeklyPrice) {
      return arr.filter((val) => val.promotionEntryId == weeklyPrice.promotionEntryId && val.price && val.unit) || null;
    },
    formatCurrency(value) {
      if (!value)
        return;
      if (this.isShowUnits)
        return value;
      const [integerPart, decimalPart] = String(value).split(".");
      const limitedIntegerPart = integerPart.slice(0, this.interLength);
      const limitedDecimalPart = decimalPart ? decimalPart.slice(0, this.decimalLength) : null;
      const formattedInteger = limitedIntegerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `$ ${formattedInteger || "0"}` + (limitedDecimalPart ? `.${limitedDecimalPart}` : String(value).includes(".") ? "." : "");
    },
    toggleRow(index2) {
      if (this.expandedRows.includes(index2)) {
        this.expandedRows = [
          ...this.expandedRows.filter((row) => row !== index2)
        ];
      } else {
        this.expandedRows.push(index2);
      }
    },
    onShowUnit() {
      if (this.isEdit)
        return;
      this.$confirm({
        title: "Show Units",
        content: "Would you like to save the changes?",
        onOk: () => {
          this.onSave();
        },
        onCancel: () => {
          this.isShowUnits = !this.isShowUnits;
        }
      });
    },
    onInputPrice(week, key, val) {
      if (key == "unit") {
        week[key] = val;
        this.$emit("onChange", week);
      }
    },
    onChangeRetail(week, val, guardrail) {
      if (week.price == val.price && week.unit == val.unit)
        return;
      week.price = val.price;
      week.unit = val.unit;
      week.seqNumber = val.seqNumber;
      week.coopDisplay = val.coopDisplay;
      week.promotionTypeId = val.id;
      this.$emit("onChange", week);
      if (this.clickedPromo.seqNumber != 0 && this.promoCounts[this.clickedPromo.promotionEntryId][this.clickedPromo.seqNumber].checkFrequency > this.promoCounts[this.clickedPromo.promotionEntryId][this.clickedPromo.seqNumber].frequency) {
        return this.promoCounts[this.clickedPromo.promotionEntryId][this.clickedPromo.seqNumber].checkFrequency -= 1;
      }
      this.promoCounts[week.promotionEntryId][week.seqNumber].remainFrequency -= 1;
      if (!guardrail.find((val2) => val2.seqNumber == this.clickedPromo.seqNumber && val2.price == this.clickedPromo.price))
        return;
      this.promoCounts[this.clickedPromo.promotionEntryId][this.clickedPromo.seqNumber].remainFrequency += 1;
    },
    onWeeklyPriceInputClick(weeklyPrice) {
      this.clickedPromo = {
        promotionEntryId: weeklyPrice.promotionEntryId,
        seqNumber: weeklyPrice.seqNumber,
        price: weeklyPrice.price,
        unit: weeklyPrice.unit
      };
    },
    isUnitBlank(weeklyPrice) {
      return weeklyPrice.unit === null || isNaN(weeklyPrice.unit) && weeklyPrice.promotionEntryId && weeklyPrice.price;
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var RetailTable = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-retail" }, [_c("section", { staticClass: "list-retail__filter card mx-4 mt-5 p-2" }, [_c("retail-filter", { on: { "onApplyFilters": _vm.fetchRetailPrice } })], 1), _c("section", { staticClass: "list-retail__table card mx-4 mb-5 mt-5 p-2" }, [_c("retail-table", { attrs: { "data-source": _vm.dataSource, "columns": _vm.columns, "is-edit": this.weeklyPrices.length === 0, "is-loading": _vm.isLoading.table }, on: { "onChange": _vm.onChangeWeeklyPrice } })], 1), _c("section", { staticClass: "list-retail__action d-flex justify-content-end px-5 py-3" }, [_c("a-row", { staticClass: "list-retail-footer neutral-1", attrs: { "justify": "end", "type": "flex" } }, [_c("a-tooltip", { attrs: { "overlay-class-name": "weekly-price-entry__frequency-tooltip", "placement": "topRight" }, scopedSlots: _vm._u([_vm.overFrequency.isOver ? { key: "title", fn: function() {
    return _vm._l(_vm.overFrequency.errors, function(error) {
      return _c("div", { key: error.name }, [_c("span", [_c("span", { style: "color: " + _vm.getGuardrailsName(error.name).code }, [_vm._v(" " + _vm._s(error.name) + " ")]), _vm._v(" of "), _c("span", { staticClass: "font-weight-bold" }, [_vm._v(_vm._s(error.ppg))]), _vm._v(" - "), _c("span", { staticClass: "font-weight-bold" }, [_vm._v(_vm._s(error.keyAcc))]), _vm._v(" exceeds the guardrail weeks ")])]);
    });
  }, proxy: true } : null], null, true) }, [_c("a-badge", { scopedSlots: _vm._u([_vm.overFrequency.isOver ? { key: "count", fn: function() {
    return [_c("a-icon", { attrs: { "theme": "twoTone", "type": "exclamation-circle", "two-tone-color": "#fa8c16" } })];
  }, proxy: true } : null], null, true) }, [_c("a-button", { attrs: { "type": "primary", "loading": _vm.isLoading.save, "disabled": _vm.dataSource.length == 0 || _vm.overFrequency.isOver }, on: { "click": _vm.onSave } }, [_vm._v(" Save ")])], 1)], 1)], 1)], 1)]);
};
var staticRenderFns$1 = [];
var ListRetail_vue_vue_type_style_index_0_lang = "";
var ListRetail_vue_vue_type_style_index_1_lang = "";
const __vue2_script$1 = {
  name: "ListRetail",
  mixins: [PromoUtils, PayloadHandlerMixin, ErrorHandlerMixin],
  components: {
    RetailFilter,
    RetailTable
  },
  inject: ["crud", "apiUrl"],
  data() {
    return {
      errors: [],
      isLoading: {
        save: false,
        table: false
      },
      dataSource: [],
      columns: [],
      weeklyPrices: []
    };
  },
  computed: {
    overFrequency() {
      let isOver = false;
      let errors = [];
      if (!this.promoCounts)
        return { isOver, errors };
      for (const key in this.promoCounts) {
        if (this.promoCounts[key]) {
          const items = this.promoCounts[key];
          for (const itemKey in items) {
            if (items[itemKey]) {
              const item = items[itemKey];
              if (item.checkFrequency > item.frequency && item.seqNumber != 1) {
                isOver = true;
                errors.push({
                  name: item.name,
                  ppg: item.ppg,
                  keyAcc: item.keyAcc
                });
              }
            }
          }
        }
      }
      return { isOver, errors };
    }
  },
  created() {
    this.crud.deleteFilter("IsInactive");
  },
  methods: {
    async fetchRetailPrice() {
      try {
        this.isLoading.table = true;
        this.isShowUnits = false;
        this.dataSource = [];
        this.weeklyPrices = [];
        await this.crud.fetchList();
        const data = await this.crud.getList();
        if (data) {
          this.dataSource = data[0].subCategories;
          this.columns = data[0].weeks.map((val) => ({
            title: this.$moment(val).format("DD MMM YY"),
            dataIndex: this.$moment(val).format("DD/MMM/YYYY"),
            key: this.$moment(val).format("DD/MMM/YYYY"),
            width: 100
          }));
        }
      } catch (error) {
        this.isLoading.table = false;
        console.log(error);
      }
      this.isLoading.table = false;
    },
    onChangeWeeklyPrice(weeklyPrices) {
      this.weeklyPrices.push(weeklyPrices);
    },
    async onSave() {
      if (this.weeklyPrices.length == 0)
        return;
      try {
        this.isLoading.save = true;
        const apiEndpoint = `${this.apiUrl}/promotion-planning/promo-entries/retail-prices`;
        const { data } = await this.axios.put(apiEndpoint, this.weeklyPrices);
        this.weeklyPrices = [];
        if (data) {
          this.$notification.success({ message: UPDATE_SUCCESS });
        }
      } catch (error) {
        this.displayErrorNotification(error);
      } finally {
        this.isLoading.save = false;
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListRetail = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-retail" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": [
    "promotion-planning.common.regions",
    "promotion-planning.common.customers",
    "promotion-planning.common.states",
    "promotion-planning.common.clusters",
    "promotion-planning.common.categories",
    "promotion-planning.common.products",
    "promotion-planning.common.sub-categories"
  ], "resource-id-name": ["code", "id", "code", "code", "code", "code", "code"] } }, [_c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "promotion-planning.promo-entries.retail-prices", "page": _vm.page } }, [_c("list-retail")], 1)], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListRetail
  },
  data() {
    return {
      apiUrl,
      page: PROMOTIONAL_PLANNING_PROMO_ENTRY_SALES_PLAN,
      itemsMenu: [
        {
          title: "Master Files",
          path: ""
        },
        {
          title: "Retail Prices",
          path: "/retail-price"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
